exports.components = {
  "component---src-components-page-js-content-file-path-content-de-pages-accessibility-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/prif-review/content/de/pages/accessibility.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-de-pages-accessibility-mdx" */),
  "component---src-components-page-js-content-file-path-content-de-pages-legal-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/prif-review/content/de/pages/legal.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-de-pages-legal-mdx" */),
  "component---src-components-page-js-content-file-path-content-de-pages-privacy-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/prif-review/content/de/pages/privacy.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-de-pages-privacy-mdx" */),
  "component---src-components-page-js-content-file-path-content-en-pages-accessibility-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/prif-review/content/en/pages/accessibility.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-en-pages-accessibility-mdx" */),
  "component---src-components-page-js-content-file-path-content-en-pages-legal-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/prif-review/content/en/pages/legal.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-en-pages-legal-mdx" */),
  "component---src-components-page-js-content-file-path-content-en-pages-privacy-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/prif-review/content/en/pages/privacy.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-en-pages-privacy-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-1-stats-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/1_stats.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-1-stats-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-2-ordnung-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/2_ordnung.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-2-ordnung-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-3-preval-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/3_preval.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-3-preval-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-4-trace-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/4_trace.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-4-trace-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-5-gerechtigkeit-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/5_gerechtigkeit.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-5-gerechtigkeit-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-6-gender-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/6_gender.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-6-gender-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-7-mali-niger-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/7_mali-niger.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-7-mali-niger-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-8-drifting-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/8_drifting.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-8-drifting-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-9-sanctions-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/posts/9_sanctions.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2022-posts-9-sanctions-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-1-stats-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/1_stats.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-1-stats-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-10-matthias-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/10_Matthias.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-10-matthias-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-2-polarregions-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/2_polarregions.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-2-polarregions-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-3-radicalization-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/3_radicalization.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-3-radicalization-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-4-cntr-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/4_cntr.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-4-cntr-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-5-postcolonialmemory-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/5_postcolonialmemory.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-5-postcolonialmemory-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-6-worldorders-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/6_worldorders.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-6-worldorders-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-7-regimecompetition-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/7_regimecompetition.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-7-regimecompetition-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-8-peacereport-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/8_peacereport.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-8-peacereport-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-9-israel-gaza-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/posts/9_IsraelGaza.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-reports-2023-posts-9-israel-gaza-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-1-stats-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/1_stats.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-1-stats-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-2-ordnung-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/2_ordnung.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-2-ordnung-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-3-preval-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/3_preval.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-3-preval-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-4-trace-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/4_trace.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-4-trace-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-5-gerechtigkeit-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/5_gerechtigkeit.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-5-gerechtigkeit-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-6-gender-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/6_gender.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-6-gender-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-7-mali-niger-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/7_mali-niger.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-7-mali-niger-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-8-drifting-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/8_drifting.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-8-drifting-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-9-sanctions-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/posts/9_sanctions.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2022-posts-9-sanctions-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-1-stats-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/1_stats.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-1-stats-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-10-matthias-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/10_Matthias.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-10-matthias-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-2-polarregions-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/2_polarregions.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-2-polarregions-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-3-radicalization-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/3_radicalization.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-3-radicalization-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-4-cntr-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/4_cntr.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-4-cntr-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-5-postcolonialmemory-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/5_postcolonialmemory.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-5-postcolonialmemory-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-6-worldorders-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/6_worldorders.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-6-worldorders-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-7-regimecompetition-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/7_regimecompetition.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-7-regimecompetition-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-8-peacereport-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/8_peacereport.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-8-peacereport-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-9-israel-gaza-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/posts/9_IsraelGaza.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-reports-2023-posts-9-israel-gaza-mdx" */),
  "component---src-components-report-js-content-file-path-content-de-reports-2022-index-mdx": () => import("./../../../src/components/Report.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2022/index.mdx" /* webpackChunkName: "component---src-components-report-js-content-file-path-content-de-reports-2022-index-mdx" */),
  "component---src-components-report-js-content-file-path-content-de-reports-2023-index-mdx": () => import("./../../../src/components/Report.js?__contentFilePath=/opt/build/repo/prif-review/content/de/reports/2023/index.mdx" /* webpackChunkName: "component---src-components-report-js-content-file-path-content-de-reports-2023-index-mdx" */),
  "component---src-components-report-js-content-file-path-content-en-reports-2022-index-mdx": () => import("./../../../src/components/Report.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2022/index.mdx" /* webpackChunkName: "component---src-components-report-js-content-file-path-content-en-reports-2022-index-mdx" */),
  "component---src-components-report-js-content-file-path-content-en-reports-2023-index-mdx": () => import("./../../../src/components/Report.js?__contentFilePath=/opt/build/repo/prif-review/content/en/reports/2023/index.mdx" /* webpackChunkName: "component---src-components-report-js-content-file-path-content-en-reports-2023-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

